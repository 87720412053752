@import "../../../styles/settings/_variables.scss";

// Project Primary colours
$red-hover: #de2934;
$grey-disabled: #d1d1d1;
$grey-header: #F5F5F5;
$grey-boxshadow: #707887;

// Headings
$headingFontFamily: $base-font-family;

﻿.page__header {
  .inner {
    align-items: center;
  }

  .page__nav-actions {
    a {
      margin-top: 2px;
    }
  }
}

@import "../settings/_variables.scss";
/* ==========================================================================
   Region global
   ========================================================================== */

.page--region {
  .page__body {
    margin-bottom: 0
  }

  .fold {
    margin-bottom: $global-spacing * 2;

    &:last-child() {
      margin-bottom: 0;
    }
  }

  #stats {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }

  #contacts {
    box-shadow: none;
  }

  .region-countries__letter {
    color: $primary-color;
    font-weight: $base-font-bold;
    @include heading(1.5rem);
    padding: $spacing-2 0 $spacing-half 0;
  }

  .region-countries__list {
    margin-top: $spacing-2;
    list-style: none;
    //@include media(medium-up) {
      //column-count: 3;
      //column-gap: ($global-spacing * 6);
      //column-rule: 1px solid $base-border-color;  
    //}
  }

  .region-countries__item {
    display: block;
    margin-top: ($spacing * 1.5);

    &:first-of-type {
      margin-top: 0;
    }

    .inactive {
      color: rgba($base-color, 0.4);
    }
  }

  .region-countries__link {
    font-size: $font-size-lg;
    font-weight: $base-font-semi-bold;
  }
  
  .region-countries__linkC {
    padding: 0 ($global-spacing / 1.5) 0 0;

    &:after {
      content: '\F145';
      font-family: 'Collecticons';
      font-size: $base-font-size;
      margin-left: $spacing;
      position: relative;
      top: 2px;
    }
  }

  .region-countries__link-op {
    font-weight: $base-font-light;
    font-size: 0.875rem;
  }

  .regional-threew {

    .Select-menu-outer {
      font-size: $font-size-xs;
      z-index: 11;
      left: 5px;
      width: calc(100% - 10px);
    }

    .tc-select {
      .tc-select-label {
        display: none;
      }

      .tc-select-input {
        .Select-control {
          border-radius: 0;
          background-color: $grey-mist;
          font-size: $font-size-xs;
        }

        &:focus-within {
          .Select-placeholder {
            color: rgba($base-color, 0.3);
          }
        }

        .Select-input {
          height: auto;

          input {
            padding: 0;
          }
        }

        .Select-value {
          background-color: $grey-cloud;
          border-radius: 0;
          margin: 1px;
          vertical-align: middle;
          font-size: $font-size-sm;

          .Select-value-icon {
            padding: 2px 3px;
            font-size: $font-size-lg;
          }

          .Select-value-label {
            padding: 2px 3px;
            font-weight: $base-font-medium;
          }
        }
      }
    }

    .box__global .tc-value {
      font-size: $font-size-heading-widget;
      font-weight: $base-font-semi-bold;
    }

    .regional-threew-overview {
      padding: $spacing-2 0 0 0;

      > .col-lg {
        display: flex;

        > div {
          width: 100%;
          padding: $spacing-half $spacing;
          margin-bottom: $spacing;
        }
      }

      .status-activity {
        .tc-heading {
          font-weight: $base-font-light;
          text-transform: uppercase;
          font-size: $font-size-xxs;
        }

        .tc-content {
          display: flex;

          .total-activity {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-shrink: 0;

            .tc-label {
              font-weight: $base-font-regular;
              letter-spacing: $letter-spacing-sm;
            }
          }

          .tc-chart-container {
            flex-grow: 1;
          }
        }
      }

      .people-overview {
        .tc-heading {
          font-weight: $base-font-light;
          text-transform: uppercase;
          font-size: $font-size-xxs;
        }

        .reached-people-count {
          display: flex;
        }

        .targeted-people {
          display: flex;
          justify-content: flex-end;
          align-items: baseline;

          .tc-label {
            padding: 3px 5px;
            text-transform: uppercase;
            font-size: $font-size-xxs;
          }

          .targeted-people-count {
            padding: 3px 5px;
            font-weight: $base-font-medium;
            color: $secondary-color;
          }
        }

        .tc-progress-bar {
          height: 10px;
          background-color: rgba(0, 0, 0, .1);
          overflow: hidden;
          border-radius: 5px;

          .tc-progress {
            background-color: $secondary-color;
            height: 100%;
            border-radius: 5px;
          }
        }
      }

      .budget-overview {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bo-text-output {
          flex-basis: 0;
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding: 6px;

          .tc-label {
            font-weight: $base-font-regular;
            letter-spacing: $letter-spacing-sm;
          }
        }
      }
    }

    .regional-movement-activities {
      position: relative;

      >.block-loading {
        position: absolute;
        z-index: 111111;
        left: 0;
        top: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .7);
      }

      >.tc-header {
        margin-top: $spacing-5;

        .tc-top {
          display: flex;
          align-items: center;

          .tc-heading {
            flex-grow: 1;
            padding: 0 10px;
            margin: 0;
            text-transform: uppercase;
            padding: 10px;
          }

          .regional-export-button {
            margin: 10px;
            flex-shrink: 0;
          }
        }

        .regional-movement-activity-filter {
          display: flex;
          align-items: flex-start;
          padding: $spacing 0;

          >* {
            flex-basis: 0;
            flex-grow: 1;
            flex-direction: column;

            .tc-select-label {
              font-size: 0.7rem;
              padding-bottom: 0;
            }

            .tc-select-input {
              align-self: stretch;
              flex-grow: 1;
            }
          }
        }
      }

      >.tc-content {
        .regional-threew-map-wrapper {
          display: flex;

          $height-map: 500px;
          $width-country-details: 295px;
          $width-popup: 295px;
          $height-popup: 180px;

          .regional-threew-map {
            height: $height-map;
            flex-grow: 1;
            
            .active-ns-count__wrap {
              height: $height-popup;
              overflow: auto;
            }

            .regional-map-threew-activity-details {
              padding: 10px!important;
              //height: $height-popup;
              width: $width-popup;
              overflow: hidden;
              display: flex;
              flex-direction: column;
              box-shadow: $base-box-shadow;

              >.tc-heading {
                color: $primary-color;
                text-transform: uppercase;
                font-weight: $base-font-medium;
                font-size: $base-font-size;
                flex-shrink: 0;
                padding: 3px 6px;
                margin: 0;

                a {
                  color: inherit;
                  display: flex;
                  align-items: center;
                }

                .tc-icon {
                  color: inherit;
                  margin-inline-start: 3px;
                }
              }

              >.tc-content {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                //overflow: auto;

                .status-counts {
                  display: flex;
                  padding: 5px 0;
                  flex-shrink: 0;

                  .tc-text-output {
                    padding: 0 3px;
                    flex: 1;

                    .tc-label {
                      padding: 3px;
                    }

                    .tc-value {
                      padding: 3px;
                    }
                  }
                }

                .active-ns-count {
                  padding: 5px 0;
                  flex-shrink: 0;
                }

                ::-webkit-scrollbar-track {
                  background-color: #eee;
                }

                ::-webkit-scrollbar {
                  background-color: #fff;
                  width: 6px;
                  height: 6px;
                }

                ::-webkit-scrollbar-thumb {
                  border-radius: 3px;
                  background-color: #aaa;
                }


                .ns-sector-details {
                  flex-grow: 1;
                  overflow: auto;
                  padding-top: $spacing-half;
                  border-top: 1px solid rgba(0, 0, 0, .1);
                  margin: 0 $spacing-half 0 0;

                  .block-loading {
                    height: 5rem;
                    margin: 1rem;

                    .spinner {
                      display: flex;
                    }
                  }

                  .ns-details {
                    padding: 3px 3px;

                    .tc-heading {
                      margin: 0;
                      font-size: $font-size-sm;
                      padding: 0 $spacing-half 0 0;
                      width: 50%;
                    }

                    > .tc-content {
                      width: 50%;
                    }

                    .tc-content {
                      display: flex;
                      flex-wrap: wrap;

                      .tc-sector {
                        padding: 0 $spacing-half 0 0;
                        color: rgba(0, 0, 0, .7);

                        &:not(:last-child) {
                          &:after {
                            content: ', ';
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .supporting-ns-list {
            box-shadow: -1px 2px 6px 1px $base-alpha-color;
            height: calc(#{$height-map} - #{$spacing-4});
            flex-shrink: 0;
            overflow: auto;
            width: $width-country-details;
            display: flex;
            flex-direction: column;
            position: relative;
            top: $spacing-2;
            padding: $spacing;

            .three-w-region-scale {
              color: $grey-silver;
              font-size: $font-size-xxs;
              font-weight: $base-font-semi-bold;
              flex-shrink: 0;
              padding: 0;
              margin: 0 16px 0 10px;
            }

            .supporting-ns-container {
              flex-grow: 1;
              overflow: auto;
              overflow-y: scroll;
            }

            ::-webkit-scrollbar-track {
              background-color: $grey-mist;
            }

            ::-webkit-scrollbar {
              background-color: #fff;
              width: 6px;
              height: 6px;
            }

            ::-webkit-scrollbar-thumb {
              border-radius: 3px;
              background-color: $grey-pearl;
            }

            .supporting-ns {
              padding: 10px 0;
              margin: 0 10px;
              border-bottom: 1px solid rgba(0, 0, 0, .1);

              .tc-label {
                color: $grey-soot;
                font-size: $font-size-xxs;
                text-transform: uppercase;
                display: flex;
                margin-bottom: $spacing-half;

                .tc-name {
                  font-weight: $base-font-semi-bold;
                  flex-grow: 1;
                  padding: 0 3px;
                  overflow: hidden;
                }

                .tc-value {
                  flex-shrink: 0;
                  padding: 0 3px;
                }
              }
            }
          }
        }

        .countries-threew-tables {
          box-shadow: $base-box-shadow;

          .country-table {
            border-bottom: 1px solid $grey-titanium;

            .tc-header-button {
              display: flex;
              width: 100%;
              color: $grey-soot;
              background-color: $grey-mist;
              font-weight: $base-font-semi-bold;
              border: 0;
              padding: 10px;
              text-align: left;
              cursor: pointer;
              outline: none;
              font-weight: bold;

              .tc-label {
                display: flex;
                flex-grow: 1;

                .tc-project-count {
                  font-weight: $base-font-light;
                  margin-inline-start: 10px;
                  color: $grey-cement;
                }
              }

              .tc-icon {
                font-size: 1.4rem;
                flex-shrink: 0;
                fill: $grey-silver;
              }

              &:hover {
                background-color: rgba(0, 0, 0, .06);
              }
            }

            .tc-content {
              padding: 0 20px;

              .tc-table {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .regional-ns-activities {
      position: relative;
      padding-bottom: $spacing-5;

      .tc-header {
        margin-top: 5rem;

        .tc-heading {
          padding: 10px;
          margin: 0;
          text-transform: uppercase;
        }
      }

      .regional-ns-activity-filter {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        margin: $spacing 0 $spacing-5;

        >* {
          flex-direction: column;
          min-width: 200px;

          .tc-select-label {
            font-size: $font-size-sm;
            padding-bottom: 0;
          }

          .tc-select-input {
            align-self: stretch;
            flex-grow: 1;
          }
        }
      }

      .ns-sankey-container {
        height: 90vh;

        .sankey-empty-message {
          color: rgba(0, 0, 0, .3);
          font-size: 1.2rem;
          display: flex;
          justify-content: center;
          padding: 10vh 1rem;
        }

        >.block-loading {
          position: absolute;
          z-index: 111111;
          left: 0;
          top: 0;
          margin: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, .7);
        }

        .sankey-link {
          fill: $secondary-color;
          fill-opacity: 0.1;

          &.sankey-link-left {
            fill: $primary-color;
          }

          &:hover {
            fill-opacity: 0.4;
          }
        }
      }
    }
  }
}

.tab__wrap--3W {
  position: relative;

  @include media(small) {
    .react-tabs__tab:nth-of-type(2) {
      padding-left: $spacing-5;
      padding-right: $spacing-5;
    }
  }
}

$country-sidebar-width: 500px;
$btn-region-countries-height: 50px;

.btn-region-countries-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
}

.btn-region-countries-trigger {
  font-weight: $base-font-medium;
  font-size: $base-font-size;
  //justify-content: flex-end;
  padding: $spacing $spacing;
  box-shadow: $base-box-shadow;
  display: inline-flex;
  width: ($country-sidebar-width);
  height: $btn-region-countries-height;
  position: relative;
  right: -300px;
  transition: all 0.1s linear;

  [dir='rtl'] & {
    left: -300px;
    right: auto;
  }
}

.btn-region-countries-trigger--active {
  right: 0;
  transition: all 0.1s linear;

  [dir='rtl'] & {
    left: 0;
  }
}

.btn-region-countries-icon {
  top: 1px;

  span {
    [dir='rtl'] & {
      transform: rotate(180deg);
      display: inline-block;
    }
  }

  span:last-of-type {
    position: relative;
    right: 6px;

    [dir='rtl'] & {
      right: -6px;
    }
  }
}

.country__sidebar {
  background-color: $white;
  box-shadow: $base-box-shadow;
  border-radius: $base-border-radius;
  position: absolute;
  top: calc(#{$btn-region-countries-height} + #{$spacing-2} + 1px);
  width: 100%;
  right: -100%;
  height: 70vh;
  overflow: auto;
  z-index: 10;
  padding: 0 $spacing-2 $spacing-2 $spacing-2;
  transition: all 0.1s linear;

  [dir='rtl'] & {
    right: auto;
    left: -100%;
  }

  @include media(small) {
    width: $country-sidebar-width;
    right: -($country-sidebar-width);
  
    [dir='rtl'] & {
      right: auto;
      left: -($country-sidebar-width);
    }
  }
}

.country__sidebar--active {
  right: 0;
  transition: all 0.1s linear;
  border-radius: 0 0 $base-border-radius $base-border-radius; 

  [dir='rtl'] & {
    left: 0;
    right: auto;
  }
}

.country__sidebar-input-search {
  @include input__search;
}

.regional-profile-key {
  background-color: #fff;
  border: 1px solid $grey-mist;
  box-shadow: $base-box-shadow;
  border-radius: $base-border-radius;
  padding: $global-spacing;
  width: 100%;
  padding: $spacing-2;
}

.regional-profile-key-block {
  align-items: baseline;
}

.regional-profile-subtitle {
  font-weight: $base-font-medium;
  position: relative;
  top: -2px;
}

.regional-profile-icon-block {
  align-items: flex-end;
  margin-top: $spacing;
}

.regional-profile-icon-col {
  margin-left: auto;
}

.regional-profile-source {
  color: $grey-platinum;
  font-size: $font-size-sm;  
}

.regional-profile-back {
  color: $primary-color;
  position: sticky;
  top: 0;
  font-size: $font-size-sm;
  display: flex;
  align-items: center;
  padding: $spacing-half 0;
  font-weight: $base-font-medium;
  cursor: pointer;
  background: none;
  border: none;

  &:hover {
    opacity: 0.64;
  }
}
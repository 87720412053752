/* ==========================================================================
   Field Report global
   ========================================================================== */

.page--frep-form {
	.inpage__body {
    margin-top: 0;
  }

  .fold {
  	padding-top: 0;
  }
}
/* ==========================================================================
   Animation
   ========================================================================== */


/* Spin clockwise
   ========================================================================== */

@keyframes spin-cw {
  from {
    transform: rotate(0deg);
  }
    to { transform: rotate(360deg);
  }
}


/* Fade-in
   ========================================================================== */

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* ==========================================================================
   Variables
   ========================================================================== */

/* Colors
   ========================================================================== */

$base-color: #000000; // Black
$base-color--light: #1b1b1b; // Black: Lighter
$primary-color: #f5333f; // Red
$secondary-color: #011e41; // Blue // Previously 24334c
$secondary-color-light: #c9d2e3;

$map-color: #23324d; // Blue light
$grey-slate: rgba(45, 70, 98, 0.6);
$grey-mist: #faf9f9; // Previously fafafa;
$grey-fog: #f3f2f3;
$grey-titanium: #eaeaea;
$grey-pearl: #d1d1d1;
$grey-cloud: #c9c3c1;
$grey-platinum: #919191;
$grey-silver: #848484;
$grey-cement: #505050;
$grey-gravel: #494949;
$grey-coal: #323232;
$grey-soot: #282d32;
$grey-10: #F4F4F4;
$black: #000;
$white: #fff;

/* State colors */

$danger-color: $primary-color; // Red
$success-color: #7FB845; // Turquoise
$warning-color: #F75C65; // Yellow
$info-color: #8D8D8D; // Blue
$light-base-color: #666666;
$state-low: #60c460;
$state-mid: #ff5014;
$state-high: $primary-color;

/* Helper colors */

$link-color: $primary-color;
$base-alpha-color: rgba($base-color, 0.1);

/* typography
   ========================================================================== */

$root-font-size: 16px;

$base-font-color: $base-color;
$base-font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-style: normal;
$base-font-light: 300;
$base-font-regular: 400;
$base-font-medium: 500;
$base-font-semi-bold: 600;
$base-font-bold: 700;
$base-font-weight: $base-font-light;
$base-line-height: 1.5;

$base-font-size: 0.875rem; // 14px
$font-size-xxs: 0.63rem; // 10px
$font-size-xs: 0.695rem; // 11px
$font-size-sm: 0.75rem; // 12px
$font-size-lg: 1rem; // 16px
$font-size-xl: 1.06rem; // 17px
$font-size-xxl: 1.16rem; // 18px
$font-size-heading-widget: 1.5rem;// 24px
$font-size-m-heading-widget: 1.72rem; //27.52
$font-size-l-heading-widget: 1.875rem; //30px
$font-size--xl-heading-widget: 2.5rem; //40px
$font-size-heading: 18px;
$font-size-heading-sm: 14px;

$code-font-family: Menlo, Monaco, Consolas, "Courier New", monospace;

$letter-spacing-sm: 0.6px;
$letter-spacing-lg: 1.6px;
$letter-spacing-heading: $letter-spacing-lg;

$heading-font-family: "Poppins", "Helvetica Neue", Helvetica, Arial, sans-serif;
$heading-font-light: 300;
$heading-font-regular: 500;
$heading-font-bold: 700;
$heading-font-weight: $base-font-semi-bold;

/* Decoration
   ========================================================================== */

/* Border radius */

$base-border-radius: 4px;
$full-border-radius: 100vh;
$input-rounded-radius: 1.8rem;

$base-border-color: rgba(#000, 0.1);
$base-border-color--light: rgba(#000, 0.05);
$base-border-width: 1px;

/* Box shadow */

$base-box-shadow: 1px 2px 6px 1px $base-alpha-color;
$box-shadow-component: 1px 1px 4px rgba($black, 0.06);

/* Grid
   ========================================================================== */

$grid-column-count: 12;
$grid-gutter: 12px;
$grid-gutter-sm: ($grid-gutter / 2);
$grid-gutter-lg: ($grid-gutter * 1.5);

/* Sizing, spacing and media queries
   ========================================================================== */

/* Sizing */

$page-header-y: 0 !default;

/* Spacing */

$global-spacing: 1rem;
$global-spacing-2: 1.5rem;
$global-spacing-3: 2rem;
$global-spacing-4: 2.5rem;
$global-spacing-5: 3rem;

/* Spacing Variables in pixels */

$spacing: 12px;
$spacing-2: ($spacing * 2);
$spacing-3: ($spacing * 3);
$spacing-4: ($spacing * 4);
$spacing-5: ($spacing * 5);
$spacing-half: ($spacing / 2);

/* Maximum Widths: Site */
$media-xsmall: 544px;
$media-small: 768px;
$media-medium: 992px;
$media-desktop: 1300px; // rarely used
$media-large: 1440px;

/* Rows */

$row-min-width: 320px;
$row-max-width: 1440px;
$jeet-max-width: $row-max-width; // reset jeet.gs max width

/* Media queries */

$xsmall-range: (0, 543px);
$small-range: (544px, 767px);
$medium-range: (768px, 991px);
$large-range: (992px, 1419px);
$xlarge-range: (1440px, 1600px);
$xxlarge-range: (1601px, 3000px);

$screen: "only screen";

.tc-select {
  display: flex;
  align-items: baseline;
  //padding: 0 4px;
  @include col-sm;

  .tc-select-label {
    padding: 6px;

    &:after {
      content: ':';
    }
  }

  .tc-select-input {
    //padding: 6px;
    min-width: 120px;
  }
}

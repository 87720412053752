.display-section {
  margin-top: $spacing-2;
  &:first-child {
    margin-top: 0;
  }

  .form__group {
    margin-bottom: $global-spacing * 2;
  }
  strong {
    font-weight: 400;
  }
}

.actions-list {
  list-style: none;
  li {
    font-weight: 400;
  }
}

.numeric-list {
  @include col(33.33%);
  dd {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: $global-spacing;
  }
}

.page--field-report {
  .inpage__title {
    margin-bottom: 0;
  }
  .inpage__introduction {
    font-family: $base-font-family;
  }
  .inpage__headline {
    margin-top: $global-spacing * 2;
  }
  .inpage__headline-actions {
    button {
      float: right;
    }
  }
}
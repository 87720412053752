﻿@import "../../../styles/settings/_variables.scss";

/* ==========================================================================
   Ui Library generic styles
   ========================================================================== */
body {
  background: $white;
}
.ui-library {
  background-color: $white;

  h1,
  h2,
  h3,
  h4 {
    font-family: $headingFontFamily;
  }

  .main {
    border-left: 1px solid #eeeeee;
    padding: 2rem 10px;
  }

  @include media(small-down) {
    .main {
      border: 0;
    }
  }

  .fold {
    box-shadow: none;
  }

  .fold__header {
    padding: 0 20px;
  }

  .inpage__header {
    padding: 60px 0;
    background-color: $grey-header;

    .inpage__headline {
      margin: 0;
    }

    .inpage__title {
      font-family: $headingFontFamily;
      font-size: 40px;
      line-height: 54.47px;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }

  .tab__wrap {
    padding: 24px 10px;
    .react-tabs__tab-panel {
      padding: 20px;
    }
  }

  .react-tabs__tab-list {
    justify-content: left;
  }

  .mobile {
    @include media(small-down) {
      display: none;
    }
  }
}

code {
  background: none;
  opacity: 1;
  padding: 0;

  textarea {
    margin: 0 auto 2rem;
    font-size: 1rem;
    line-height: 1.4rem;
    background: $grey-coal;
    color: $grey-titanium;
    padding: 2rem;
    display: block;
    white-space: pre-line;
    min-height: 200px;
    resize: none;
    border: 1px solid $grey-coal;
    min-width: 100%;
    opacity: 1;

    &:focus {
      padding: 2rem;
      border: 0;
      outline: 0;
      background: $grey-coal;
    }
  }
  &:focus {
    border: 0;
    outline: 0;
  }

  button {
    margin: 0 20px 20px 0;
  }
}

.nav-global-menu {
  padding-top: 2px;
  > li {
    margin: 0;
  }

  a.button {
    color: $white;
  }
}

.page__header--mobile .page__menu a:before {
  top: 6px;
}

.form__option__text em {
  font-size: 0.875rem;
}

.form-message {
  display: none;

  &.show {
    display: inline;
  }
}

.page__header__inner__wrap {
  border-bottom: none;
  box-shadow: 0px 3px 8px rgba($grey-boxshadow, 0.24);
}
#map { 
  position: absolute; 
  top: 0; 
  bottom: 0; 
  width: 100%; 
}
.colours {
  .colour {
    display: flex;
    flex-wrap: wrap;

    @include media(large) {
      display: flex;
      flex-wrap: nowrap;
    }

    &-text {
      display: block;
      width: 100%;

      p {
        margin-bottom: 0.5rem;
      }
    }

    .box {
      width: 130px;
      margin: 1rem 0.7rem 2.4rem 0.7rem;
      border: 1px solid grey;
      display: flex;
      flex-direction: column;

      @include media(medium-up) {
        margin: 1rem 2.4rem 2.4rem 0;
      }

      @include media(large) {
        flex-shrink: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &-bg {
        min-height: 130px;
        width: 100%;
      }

      &-text {
        padding: 1rem;
        color: #000000;
        border-top: 1px solid grey;

        p {
          &.small-print {
            font-weight: bold;
            margin: 0;
          }

          &.box-hex {
            font-size: 13px;
            margin: 0;
            line-height: 18px;
          }

          &.box-rgb {
            font-size: 12px;
            margin: 0;
            line-height: 15px;
          }
        }
      }
      // Primary
      .brand {
        background: $primary-color;
      }

      .complement {
        background: $secondary-color;
      }

      .dark-grey {
        background: $grey-coal;
      }
      
      // Semantic
      .positive {
        background: #7fb845;
      }

      .informational {
        background: #3FA2F7;
      }

      .negative {
        background: #f75c65;
      }

      .warning {
        background: #ffb443;
      }

      // Greyscale
      .black {
        background: #000000;
      }
      .grey9 {
        background: #323232;
      }
      .grey8 {
        background: #525252;
      }
      .grey7 {
        background: #6f6f6f;
      }
      .grey6 {
        background: #8d8d8d;
      }
      .grey5 {
        background: #a8a8a8;
      }
      .grey4 {
        background: #c6c6c6;
      }
      .grey3 {
        background: #e0e0e0;
      }
      .grey2 {
        background: #f4f4f4;
      }
      .grey1 {
        background: #fafafa;
      }
    }
  }
}
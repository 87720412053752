@import "../settings/_variables.scss";
/* ==========================================================================
   Global: Page Prime Nav
   ========================================================================== */

// Mixin for consistent style for dropdown items in header navigation
@mixin drop-menu-header-dropdown {
  padding: $global-spacing/2;
  font-weight: $base-font-regular;
  font-size: $font-size-sm;

  &:hover, &:focus {
    background-color: $grey-titanium;

    a {
      opacity: 1;
    }
  }
}


.page__prime-nav {
  flex: 1;
  display: flex;
  position: relative;

  button, a {
    font-family: $base-font-family;
    font-weight: $base-font-regular;
    margin-bottom: 0;
  }

  @media (min-width: 1200px) {
    width: 58%;
  }
}

.page__meta-nav {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .tc-dropdown-menu {
    &:focus {
      outline: 0;
    }
  }
}

.page__meta-nav-elements {
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-inline-end: 0.5px solid $grey-titanium;
  padding: 0 $spacing;
  @include media(small-down) {
    padding-inline-start: 0;
  }
}
.page__meta-nav-elements:hover {
  text-decoration: none;
}
.page__meta-nav-elements:active {
border-bottom: 2px solid #f5333f;
}

button#header-dropdown-lang {
  background: no-repeat;
  color: #007bff;
  border: 0px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border-inline-end: 0.5px solid $grey-titanium;
  padding: 0 12px;
}

.page__meta-nav-elements--lang {
  padding-inline-start: 0;    
}

.page__meta-nav-elements-borderless {
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-inline-end: 1rem;
  padding-inline-start: 1rem;
}

.drop__title--page__meta-nav {
  // margin-inline-start: -.5rem !important;
  // margin-inline-end: -.5rem !important;
  padding: $spacing-half $spacing-half 0 $spacing-half;
}

.drop__menu--page__meta-nav {
  padding: 0;

  .drop__menu-item {
    @include drop-menu-header-dropdown;
  }
}

/* Global menu */

.nav-global-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.875rem;
  line-height: 1.25rem;
  flex: 1;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;

  a {
    font-weight: $base-font-medium;
    color: inherit;
    display: block;
  }

  > li {
    margin: 0 $global-spacing;

    &:first-child {
      margin-inline-start: 0;
    }

    &:last-child {
      margin-inline-end: 0;
    }
  }
}

/* Site Search */

.nav-global-search {
  width: 100%;

  .Select-placeholder, .Select-input {
    padding-left: $spacing-2;

    [dir='rtl'] & {
      padding-right: $spacing-2;
      padding-left: 0;
    }
  }

  .Select-control, .Select-input {
    background-image: url('/assets/icons/collecticons/magnifier-left-grey.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: left 44%;

    [dir='rtl'] & {
      background-position: right 44%;
    }
  }

  .Select-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $grey-cloud;
  }

  .Select-arrow {
    border-color: $primary-color transparent transparent;
  }

  .Select-menu, .Select-menu-outer {
    border-radius: 0;
  }

  .Select.is-open > .Select-control {
    border-radius: $input-rounded-radius/1.5 $input-rounded-radius/1.5 0 0;
    border-radius: 0;
  }

  @media (min-width: 1200px) {
    width: 42%;
  }
}

.gsearch {
  position: relative;

  .form__group {
    margin-bottom: 0;
  }

  .form__label {
    @extend .visually-hidden;
  }

  .form__control {
    border-radius: $full-border-radius;
    padding-inline-start: 3rem;
    font-size: 0.875rem;
    color: $base-color;
    &:hover {
      box-shadow: inset 0 0 0 $base-border-width rgba($base-color, 0.48);
    }

    &:focus,
    &--focus,
    &:active,
    &--active {
      outline: 0;
      box-shadow: inset 0 0 0 $base-border-width rgba($base-color, 0.48);
    }
    }
}

.gsearch__button {
  @extend .button, .button--text-hidden, .button--primary-plain;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  padding-inline-start: 1rem;

  &::before {
    @extend %collecticon-magnifier-right;
  }
}


/* User menu */

.drop__togle--user {
  @extend .button, .button--base-plain, .button--text-hidden;

  &::before {
    @extend %collecticon-user;
  }
}

.desktop__header {
  display: none;
  @include media(medium-up) {
    display: block;
  }
}
.page__header--mobile {
  @include media(medium-up) {
    display: none;
  }
  .nav-global-search {
    min-width: 17rem;
  }
  > .inner:first-of-type {
    border-bottom: 1px solid $primary-color;
  }

  .inner {
    padding-bottom: $global-spacing/2;
    padding-top: $global-spacing/2;
  }
  .page__headline {
    img {
      margin-top: $global-spacing/2;
      height: 2rem;
      float: right;
    }
  }
  .page__title {
    margin-left: auto;
  }
  .page__menu {
    a {
      position: relative;
      font-size: 1.5rem;
      &:before {
        position: absolute;
        top: $spacing;
        left: 0;
        @extend %collecticon-hamburger-menu;
      }
    }
  }
  .nav__block {
    position: absolute;
    z-index: 3;
    top: 0;
    min-height: 100vh;
    width: 100%;
    left: -100%;
    transition: left 0.2s;
    background-color: #FFF;
    padding-top: $global-spacing;
    font-weight: $base-font-regular;
    ul {
      list-style: none;
    }
    li {
      margin-bottom: $global-spacing;
    }
    .nav-block-menu {
      list-style: none;
      margin-top: $global-spacing;
      border-bottom: solid 1px rgba($base-color, 0.2);
    }
    > .inner {
    
    }
    &--open {
      left: 0;
    }
  }

  .nav-block-submenu {
    margin-top: $global-spacing;
    li {
      font-weight: $base-font-light;
      margin-inline-start: 0.5rem;
      font-size: 0.875rem;
    }
  }
  .mobile__actions {
    padding: $global-spacing 0;
    button {
      float: right;
    }
  }
}

// Dropdown for Create Report

.drop__menu__field__report.drop__menu {
  padding: 0;

  .drop-trans--down.drop__content {
    transform: 0;
  }

  .drop__menu-item {
    @include drop-menu-header-dropdown;
  }  
} 

.tc-dropdown-menu.drop__toggle--field-report-new {
  font-weight: $base-font-semi-bold;
  letter-spacing: 0.5px;
  padding-inline-start: ($global-spacing * 2);
  padding-inline-end: ($global-spacing * 2);
  position: relative;
  top: ($global-spacing * 1.3);
  color: $primary-color;

  @include media(medium) {
    padding-inline-start: ($global-spacing * 2.7);
    padding-inline-end: ($global-spacing * 2.7);
  }

  .drop__menu {
    padding: 0;
  }

  &::after {
    font-size: $font-size-sm;
    position: absolute;
    right: ($global-spacing / 1.5);
  }

  &:hover, &.active {
    color: #fff;
    background: $primary-color;

    &::after {
      color: #fff;
    }
  }

  @include media(medium-up) {
    top: auto;
  }
}

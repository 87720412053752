/* ==========================================================================
   About Page
   ========================================================================== */

.page--about {
  .fold--main {
    background: none;
  }

  .page__body {
    margin-bottom: 0;
  }

  .inpage__body {

    > .inner {
      max-width: none;
      padding: 0;
    }
  }

  .inpage__header {
    padding-bottom: $spacing-4;
  }

  .logo__group {
    background-color: #fff;
    padding: ($global-spacing * 5) 0;
  }

  .logo__list {
    list-style: none;
    margin: $global-spacing-3 auto 0 auto;
  }

  .logo__item {
    margin-top: $global-spacing;
    margin-bottom: $global-spacing;

    @include media(large-up) {
      margin-top: 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }
    a {
      display: block;
    }
  }
  .logo__title {
    font-weight: $base-font-bold;
  }
}

.fold--about-iframe-video {
  box-shadow: none;
  margin-bottom: $spacing-2;
  padding-bottom: $spacing-2;
}

.iframe__about__block {
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  top: -80px;

  @include media(medium-up) {
    top: -130px;
  }
}

.section__about__guides__ref {
  margin-top: -50px;
}

.box__global__wrap__about {
  margin-top: $spacing;
}

.box__global__content--ref__link {
  font-weight: $base-font-medium;
  margin: 0 0 $global-spacing 0;
  display: block;
}

.box__global--about-guides, .box__global--about-ref {
  margin: 0 0 $global-spacing-2 0;

  .box__global__heading {
    @include media(medium-up) {
      min-height: 124px;
    }
  }
}

.icon-about-ref {
  font-size: 0.55rem;
  font-weight: $base-font-bold;
  padding: 0 0 0 ($global-spacing/2);
}

.box__global--about-guides {
  .box__global__content--ref__link {
    display: flex;
    align-items: center;
  }

  .icon__circle {
    margin-inline-end: ($global-spacing / 2);
    flex-shrink: 0;
  }
}

.box__global--about-ref {
  .box__global__content--ref__link {
    display: block;
  }
}

.fold__about__video__carousel {
  padding: $global-spacing-3 0;
  margin: $global-spacing 0 0 0;
}

.about__video__carousel {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  overflow: initial !important;

  img {
    max-width: 100%;
    height: auto;
  }

  .BrainhubCarouselItem {
    margin: $global-spacing;

    > div {
      width: 100%;
    }
  }

  .BrainhubCarousel__trackContainer .BrainhubCarousel__track {
    align-items: flex-start;
  }

  .BrainhubCarousel__arrowLeft {
    left: -40px;
  }

  .BrainhubCarousel__arrowRight {
    right: -40px;
  }

  .BrainhubCarousel__arrows, .BrainhubCarousel__arrows:hover, .BrainhubCarousel__arrows:hover:enabled, .BrainhubCarousel__arrows:disabled {
    background: transparent;
  }

  .BrainhubCarousel__arrows {
    position: absolute;
    z-index: 1;

    span {
      border-width: 5px 5px 0 0;
      border-color: $grey-pearl;
    }

    &:enabled span {
      border-color: $grey-silver;
    }

    &:hover span, &:hover:enabled span {
      border-width: 5px 5px 0 0;
      border-color: $primary-color;
    }

    &:disabled {
      cursor: default;

      span {
        border-color: $grey-titanium;
      }
    }
  }
}

.about__video__carousel__each__heading {
  font-weight: $base-font-semi-bold;
  padding: ($global-spacing/2) 0 0 0;
}

.about__video__carousel__each__subheading {
  font-size: $font-size-sm;  
}

.about__resources__block {
  clear: both;
  padding: ($global-spacing * 5) 0 ($global-spacing * 3.5);
}

.about__resources__row {
  margin-top: $spacing;

  > .col {
    display: flex;
  }
}

.box__global--resources {
  position: relative;
  margin: 0 0 $global-spacing-3 0;
  width: 100%;

  .box__global__content--ref__link {
    padding-inline-end: $global-spacing-3;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.icon__about__resources {
  color: $primary-color;
  font-size: 24px;
  padding: $global-spacing;
  position: absolute;
  bottom: 0;
  right: 0;
}

.about__contact__block {
  padding: 0 0 $global-spacing-3 0;
  clear: both;
}

.about__contact {
  max-width: 640px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.about__contact__info__block {
  padding: $global-spacing-2 0;
  align-items: center;
}

.about__contact__link {

  .button {
    font-size: $font-size-sm;

    @include media(small-up) {
      font-size: inherit;
    }
  }
}

.about__contact__col {
  @include media(medium) {
    @include col(33.33%);
    margin-bottom: $global-spacing;  
  }
}

.about__contact__region {
  font-weight: $base-font-bold;
}

.about__contact__name {
  font-weight: $base-font-medium;
}

.about__contact__email {
  color: $primary-color;
  font-weight: $base-font-medium;
  margin-bottom: $global-spacing-2;
  display: block;

  @include media(medium-up) {
    margin-bottom: $global-spacing;  
  }
}
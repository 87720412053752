/* ==========================================================================
   Buttons
   ========================================================================== */

.iframe__embed {
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 56.25%;
}

.iframe__embed__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
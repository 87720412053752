@import "../settings/_variables.scss";

.recharts-cartesian-axis {
	font-size: 0.75rem;
  .recharts-cartesian-axis-ticks {
    text {
      fill: rgba($base-color, 0.68);
    }
    &:nth-child(even) {
      opacity: 1;
    }
  }
  .recharts-cartesian-axis-tick-line {
    stroke: rgba($base-color, 0.28);
  }
}

.inpage__headline-charts, .inpage__headline-charts--two-col, .inpage__body-charts {
  .emergencies__list {
    margin: 0;
    list-style: none;
  }

 .emergencies__item {
    @include flex;
    @include row;
    align-items: center;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }
    .key {
      color: $grey-soot;
      font-size: $font-size-xs;
      font-weight: $base-font-medium;
      position: relative;
      display: block;
      text-align: inline-end;
      @include col(33.33%);

      &:after {
        content: '';
        width: 1px;
        height: 100%;
        background-color: $base-border-color;
        position: absolute;
        right: 0;
        top: 0;
        display: block;

        [dir='rtl'] & {
          left: 0;
          right: auto;
        }
      }
    }
    .value {
      @include col(66.66%);
      flex-grow: 1;
      display: flex;
      align-items: baseline;
      .progress-bar {
        background-color: transparent;
        margin-inline-end: $spacing;
      }
      .progress-bar__value {
        background-color: $primary-color;
        height: 12px;
      }
    }

    .key__value__emergency {
      min-width: 20px;
    }
  }
}

.emergenciest__list--static {
  .emergencies__item {
    cursor: default;
   }
 }

.recharts-legend-item-text {
  font-size: $font-size-xs;
}

.charts__container__rtl {
  direction: ltr; // fix for recharts tooltips' position on hover in RTL languages like Arabic
}
﻿// Home Page styles

.homepage {
  background: url('./../../graphics/content/homepage_bg.png') no-repeat 0;
  background-size: cover;
  padding: 0;
  min-height: 555px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media(large) {
    background: url('./../../graphics/content/homepage_bg.png') no-repeat 0;
    background-size: contain;
  }

  &__body {
    padding: 20px 0;  
    font-size: 1.06rem;
    color: $white;

    img {
      display: flex;
      margin-left: auto;
    }
  }
}
.homepage-note {
  background: url('./../../graphics/content/background-grey.png') no-repeat 0 ;
  background-size: cover;
  padding: 0;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -48px;

  @include media(large) {
    background: url('./../../graphics/content/background-grey.png') no-repeat 0 right;
    background-size: cover;
    background-position-x: left;
    background-position-y: 0;
  }

  &__body {
    padding: 20px 0;  
    font-size: 1.06rem;
    color: $white;

    img {
      display: flex;
      margin-left: auto;
    }
  }
}
.tab__wrap {
  background-color: #fff;
  padding: $spacing-2 0;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  &__tab-list {
    margin: 0 0 10px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;

    @include media(small) {
      flex-direction: row;
    }
  }

  //primary
  &__tab {
    color: $black;
    display: inline-block;
    font-family: $heading-font-family;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-sm;
    font-size: 14px;
    border: 1px solid transparent;
    border-bottom: none;
    background-color: $grey-mist;
    position: relative;
    list-style: none;
    padding: $spacing $spacing-2;
    font-size: $base-font-size;
    cursor: pointer;
    margin-inline-end: $spacing-half;

    @include media(small) {
      font-size: $base-font-size;
    }

    @include media(small) {
      padding: $spacing ($spacing * 2.5);
    }

    &:last-of-type {
      margin-inline-end: 0;
    }

    &--selected {
      background: #fff;
      font-size: $base-font-size;
      color: $primary-color;
      box-shadow: 0px 15px 1px -14px $primary-color;

      @include media(small) {
        border-color: $base-border-color;
        bottom: -1px;
      }
    }

    &--disabled {
      color: GrayText;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px $grey-pearl;
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: #fff;
      }
    }
  }

  .secondary &__tab {

    ul li {
      background-color: $grey-mist;
    }
    &-list {
      border-color: #F7F7F7;

      li:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }

      li:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }

      li {
        border: hidden;
        background: $grey-mist;
        margin: 0px;
        box-shadow: none;
      }
    }

    border-bottom: hidden;

    &--selected {
      background-color: $primary-color !important;
      color: white;
      box-shadow: none;
    }
  }

  &__tab-panel {
    display: none;

    &--selected {
      display: block;
    }
  }
}

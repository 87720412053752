.project-form-modal {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  position: relative;

  .tc-header {
    display: flex;
    align-items: center;
    max-width: 1260px;
    padding: 1rem;
    width: 100%;

    .tc-heading {
      margin: 0;
      font-size: 2.25rem;
    }

    .tc-actions {
      padding: 24px;

      @include media(small) {
       display: flex;
       justify-content: flex-end;
      }
    }
  }

  .block-loading {
    margin: 0;
    position: absolute;
    background-color: rgba(255, 255, 255, .7);
    z-index: 111;
    width: 100%;
    height: 100%;
  }

  .project-form {
    max-width: 1260px;
    padding: 1rem;
    width: 100%;
    overflow: auto;
  }
}

.project-form {
  .multi-input-section {
    .section-content {
      display: flex;
    }
  }

  .project-form-select {
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex-grow: 1;

    .tc-select-label {
      padding: 0;
      font-size: $font-size-sm;
      text-transform: uppercase;
    }

    .tc-select-input {
      width: 100%;
      padding: 0;
    }
  }

  .tc-raw-input-label {
    padding: 0;
    font-size: $font-size-sm;
    text-transform: uppercase;
  }

  .tc-faram-checkbox {
    margin-top: 12px;
  }

  .tc-text-output {
    padding: 3px;
  }

  .district-select-container {
    display: flex;
    align-items: flex-start;

    button {
      margin-top: calc(1rem + 12px);
      flex-shrink: 0;
    }
  }

  .budget-input-container {
    display: flex;
  }

  .tc-non-field-errors {
    color: $danger-color;
    text-align: center;
    font-weight: $base-font-bold;
  }

  .tc-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;

    .three-w-hidden-submit-button {
      display: none;
    }
  }
}

.project-form-input-section {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  margin: 1.2rem 0;
  background-color: #f3f2f3;


  .section-title {
    flex-basis: 27%;
    min-width: 300px;
    flex-shrink: 0;
    padding-inline-end: 24px;

    .tc-title {
      font-weight: bold;
      font-size: 1rem;
    }

    .tc-help-text {
      color: rgba(0, 0, 0, .48);
      font-size: 0.8rem;
      margin-top: 1rem;
    }
  }

  .section-content {
    display: flex;
    flex-grow: 1;
    align-items: flex-start;

    >* {
      flex-basis: 0;
      flex-grow: 1;
    }
  }
}


.three-w-region-progress-bar {
  height: 10px;
  background-color: $grey-mist;
  overflow: hidden;
  border-radius: 5px;

  .three-w-region-progress-bar-progress {
    height: 100%;
    background-color: $primary-color;
    border-radius: 5px;
  }
}

.three-w-region-scale {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey-pearl;
  padding: 6px 0;
}

.tc-confirm-modal {
  border-radius: 4px;
  max-height: 50vh;
  width: 420px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-shadow: $base-box-shadow;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 0 0 $global-spacing * 1.5 0;

  .tc-heading {
    border-bottom: 1px solid #d1d1d1;
    flex-shrink: 0;
    font-size: $base-font-size;
    font-weight: $base-font-semi-bold;
    padding: ($global-spacing * 0.9) ($global-spacing * 1.2) ($global-spacing * 0.9) ($global-spacing * 1.5);
    margin: 0;
  }

  .tc-message {
    flex-grow: 1;
    overflow: auto;
    padding-top: $global-spacing * 1.5;
    padding-left: $global-spacing * 1.5;
    padding-right: $global-spacing * 1.5;
    padding-bottom: $global-spacing * 3.5;
  }

  .tc-footer {
    flex-shrink: 0;
    display: flex;
    justify-content: flex-end;
    padding: $global-spacing / 3 $global-spacing * 1.5;

    .button {
      font-size: $font-size-sm;
      padding-left: $global-spacing * 2.5;
      padding-right: $global-spacing * 2.5;
    }
  }
}

.table--contacts {
  td {
    padding-bottom: $spacing-half;
    padding-top: 0;
  }
}
  
.table__cell--progress__personnel {
  width: 40%;
}

.progress__block__personnel {
  .progress-bar {
    background-color: transparent;
    display: flex;
  }

  .progress-bar__value {
    border-radius: $base-border-radius;
    height: 24px;
  }
}

.progress__block__tooltip__heading {
  border-bottom: 1px solid $base-border-color;
  margin: 0 0 $spacing-half 0;
  padding: 0 0 $spacing-half 0;
}

.fold__table--deploy-personnel {
  .fold__body {
    position: relative;  
  }
}

.personnel__table__daterange {
  width: 40%;
  display: flex;
  justify-content: space-between;
  font-size: $font-size-xxs;
  font-weight: $base-font-medium;
  position: relative;
  top: 0;

  [dir='ltr'] & {
    margin-left: auto;
  }

  [dir='rtl'] & {
    margin-right: auto;
  }

  @include media(small-down) {
    display: none;
  }
}

.personnel__table__date__current {
  display: flex;
  font-size: $font-size-xs;
  font-weight: $base-font-medium;  
  width: 40%;
  position: relative;
  top: 37px;
  margin-inline-end: 30px;

  [dir='ltr'] & {
    margin-left: auto;
  }

  [dir='rtl'] & {
    margin-right: auto;
  }

  @include media(small-down) {
    display: none;
  }
}

.personnel__date__graphic__block {
  display: flex;
  width: 40%;
  position: absolute;
  left: 60%;
  top: 0;
  bottom: 0;

  [dir='rtl'] & {
    right: 60%;
  }

  @include media(small-down) {
    display: none;
  }
}

.personnel__date__graphic {
  position: absolute;
  bottom: 42px;
  top: 61px;
  margin-top: 1px;
  background:
    repeating-linear-gradient(to bottom,transparent 0 4px, $white 4px 7px),
    linear-gradient(to bottom, $grey-platinum, $grey-platinum);
  background-size: 2px 100%;
  background-repeat:no-repeat;
  width: 1px;
}

.personnel__table__date {
  &:first-of-type {
    margin-inline-start: -30px;
  }

  &:last-of-type {
    margin-inline-end: 30px;
  }
}

.login-link {
  @include link-underline;
  padding-left: $spacing / 2;
}

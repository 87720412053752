/* Per Forms
   ========================================================================== */

.per_form_area {
  font-weight: bold;
  font-size: 25px;
}

.per_form_ns {
  font-weight: bold;
  font-size: 20px;
}

.per_form_question {
  font-size: 15px;
  text-decoration: underline;
}

.per__form__col {
  margin-top: $global-spacing;

  .button {
    width: 100%;
  }
}

.per__component__header {
  margin-bottom: 2rem;
}

.list__each__block {
  font-weight: $base-font-semi-bold;
  background-color: $grey-fog;
  margin: 0 0 $global-spacing 0;
  padding: $global-spacing;
}

.list__each__button {
  margin-inline-start: auto;
}

.per__delete_button {
  color: $primary-color;
  float: right;
}

// This is awful CSS, but will do for now
.per__right_button {
  margin-left: $spacing;
  margin-right: $spacing;
}

.per__right_button--save {

  @include media(medium) {
    position: relative;
    top: $spacing-3;
  }
}

/* Account Forms
   ========================================================================== */

//.form__account__info {
//@include column(6/12);
//}

.account__op__each__link {
  padding-bottom: ($spacing * 1.5);
}

.account__op__each__button {
  padding-bottom: ($spacing * 1.5);
}

.per__container {
  margin-top: $spacing-3;
}

.per__filters {
  .form__options-group {
    @include row;
    @include flex;

    .form__option {
      @include col(50%);

      @include media(medium) {
        width: 100%;
      }
    }
  }
  .form__group {
    margin-bottom: $global-spacing;
  }
  .form__label {
    font-family: $heading-font-family;
    font-size: 1.25rem;
  }
}

.per__cards {
  > .col {
    display: flex;
  }
}

.per_areas__header {
  .form__label {
    font-size: 1.25rem;
  }

  margin-top: $global-spacing * 3;
  @include media(medium-up) {
    margin-top: 0;
  }
}

.per__card {
  background: #fff;
  padding: $spacing $spacing-2;
  box-shadow: 0 2px 4px 1px $base-alpha-color;
  width: 100%;

  margin-bottom: $spacing-2;
  .updated {
    font-size: 0.75rem;
    color: rgba($base-color, 0.48);
    text-align: end;
    margin-top: $global-spacing;
    @include media(large-up) {
      margin-top: 0;
    }
  }

  .card__col {
    p {
      font-size: 0.875rem;
      line-height: 1rem;
    }
    &:last-child {
      // border-inline-start: solid 1px rgba($base-color, 0.1);
      padding-inline-start: $global-spacing;
    }
    .link--primary {
      line-height: 1.5;
    }
  }
  .card__label {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: $base-font-regular;
  }
}

.per__card-header {
  // background-color: $grey-mist;
  padding-bottom: $global-spacing;
  margin-bottom: $global-spacing;
  border-bottom: solid 1px rgba($base-color, 0.1);
}

.per-table--border-bottom {
  thead th {
    box-shadow: none;
    font-weight: $base-font-semi-bold;
    text-align: inline-start;
  }

  thead th, td {
    font-size: $base-font-size;
  }

  th {
    .drop__toggle--caret::after {
      content: '\EA0A';
      font-size: $font-size-xxs;
      vertical-align: baseline;
    }
  }

  td {
    color: $grey-soot;
    border-bottom: 1px solid $base-border-color--light;
    font-weight: $base-font-regular;
  }

  // Progress bar styling for tables with bottom borders
  .progress-bar {
    background-color: rgba(50, 50, 50, 0.1);
    border-radius: 0;
  }

  .progress-bar__value {
    background-color: $primary-color;
    border-radius: 0;
    height: 4px;
  }

  .progress_value_funding_table {
    margin-bottom: ($global-spacing / 3);
    display: block;
  }
}

.mt-gs {
  margin-top: $global-spacing;
}

.new-assessment-button {
  padding-top: $global-spacing * 2;
  padding-bottom: $global-spacing * 3;
}

.form__group__per  {
  @include form__group;
  background-color: #fff;
  margin-bottom: 0;
  padding-top: 0.9rem;
  padding-bottom: 0.8rem;
  margin-left: 0 !important;
  margin-right: 0 !important;
  @include row;

  > .form__group__wrap {
    @include row;

    @include media(medium-up) {
      @include flex;
    }

    > .form__inner-header {
      display: block;
      @include col;

      @include media(medium-up) {
        @include col(30%);
      }
    }

    > .form__inner-body {
      @include col;

      @include media(medium-up) {
        @include col(70%);
      }
    }
  }

  .form__option--custom-radio {
    .form__option__text {
      font-weight: $base-font-regular;
    }
  }
}

.per-chart {
  margin-bottom: 1.5rem;
}

.per-inpage__header {
  padding-top: $global-spacing;

  .inpage__headline {
    margin-top: $global-spacing * 2;
    margin-bottom: $global-spacing * 3;
  }
}

.per--button--clear-source {
  @include button-trash;
}

.per-error-message {
  font-size: .875rem;
}
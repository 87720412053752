@import "../settings/_variables.scss";

/* ==========================================================================
   Alerts
   ========================================================================== */

.alert {
  @extend .antialiased;
  position: relative;
  border-radius: $base-border-radius;
  background: rgba($grey-10, 0.92);
  padding: $global-spacing ($global-spacing * 2.5) $global-spacing ($global-spacing * 1.75);
  color: #000;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 $global-spacing 0;


  &.alert--alert.banner.alert {
    background: #F75C65;
    color: white;
    text-align: center;
    padding: 5px;
    padding-top: 10px !important;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &.primary {
    color: #FFF;

    .icon {
      top: 4px;
      position: relative;
    }

    .alert__button-dismiss {
      color: #FFF;
    }

    &.warning {
      background-color: $warning-color;
    }

    &.error {
      background-color: $danger-color;
    }

    &.success {
      background-color: $success-color;
    }

    &.info {
      background-color: $info-color;
    }
  }

  &.secondary {
    .icon {
      top: 4px;
      position: relative;

      .warning {
        color: $warning-color;
      }

      .error {
        color: $danger-color;
      }

      .success {
        color: $success-color;
      }

      .info {
        color: $info-color;
      }
    }
  }

  &.message {
    background-color: #FFF;
    color: $danger-color
  }

  .text {
    position: relative;
    padding-left: 10px;
    top: -3px;
  }

  &::before {
    position: absolute;
    top: -$global-spacing / 2;
    bottom: -$global-spacing / 2;
    left: 0px;
    width: $global-spacing / 2;
    background: rgba($base-color, 0.92);
    border-radius: $base-border-radius / 2;
    content: "";
  }

  /* Animation */
  transform: translateZ(0);
  transition: all 0.24s ease 0s;
}


/* Alert context modifiers
   ========================================================================== */

.alert--success {
  &::before {
    background: rgba($success-color, 0.92);
  }
}

.alert--info {
  &::before {
    background: rgba($info-color, 0.92);
  }
}

.alert--warning.primary, .alert--warning.secondary, .banner {
  &::before {
    background: rgba($warning-color, 0.92);
  }
}

.alert--error.primary, .alert--error.secondary {
  &::before {
    background: rgba($danger-color, 0.92);

  }
}

.alert--error.message {
  &::before {
    background: unset;
  }
}


/* Alert position modifiers
   ========================================================================== */

.alert--popover {
  position: fixed;
  z-index: 9996;
  bottom: $global-spacing;
  right: $global-spacing;
  padding: ($global-spacing * 1.5) ($global-spacing * 2.5) ($global-spacing * 1.5) ($global-spacing * 1.75);
  margin: 0;
  max-width: 24rem;
}


/* Alert buttons
   ========================================================================== */

.alert__button-dismiss {
  @extend .button, .button--small, .button--base-glass, .button--text-hidden;
  position: absolute;
  background-color: unset;
  top: 15px;
  right: $global-spacing / 2;
  z-index: 10;

  &::before {
    @extend %collecticon-sm-xmark;
  }
}

.alert-container {
  pointer-events: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 9996;
  top: 0;
  bottom: 0;
  right: 0;
  width: 24rem;
  padding: $global-spacing $global-spacing 0 $global-spacing;

  & > * {
    pointer-events: auto;
  }
}

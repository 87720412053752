@import "../settings/_variables.scss";

/* ==========================================================================
   Icons: Styles
   ========================================================================== */


.icon__circle {
  color: #fff;
  background-color: $primary-color;
  border-radius: 50%;
  font-size: $font-size-xxs;
  font-weight: $base-font-semi-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.icon-demo {
  width: 100px;
}
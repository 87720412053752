.table-deployed-personnel-block {
  .table__header--name, .table__cell--name {
    width: 158px;
  }  

  .table__header--type, .table__cell--type {
    width: 148px;
  }
}

.readiness__container {
  margin-top: $spacing-3;
}

.readiness__filters {
  .form__options-group {
    @include row;
    @include flex;

    .form__option {
      @include col(50%);
      
      @include media(medium) {
        width: 100%;
      }
    }
  }
  .form__group {
    margin-bottom: $global-spacing;
  }
  .form__label {
    font-family: $heading-font-family;
    font-size: 1.25rem;
  }
}

.readiness__cards {

  > .col {
    display: flex;
  }
}

.readiness__header {
  .form__label {
    font-size: 1.25rem;
  }

  margin-top: $global-spacing * 3;
  @include media(medium-up) {
    margin-top: 0
  }
}

.readiness__card {
  background: #fff;
  padding: $spacing $spacing-2;
  box-shadow: 0 2px 4px 1px $base-alpha-color;
  width: 100%;

  margin-bottom: $spacing-2;
  .updated {
    font-size: 0.75rem;
    color: rgba($base-color, 0.48);
    text-align: end;
    margin-top: $global-spacing;
    @include media(large-up) {
      margin-top: 0;
    }
  }

  .card__col {
    p {
      font-size: 0.875rem;
      line-height: 1rem;
    }
    &:last-child {
      border-inline-start: solid 1px rgba($base-color, 0.1);
      padding-inline-start: $global-spacing;
    };
    .link--primary {
      line-height: 1.5;
    }
  }
  .card__label {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: $base-font-regular;
  }
}

.readiness__card-header {
  padding-bottom: $global-spacing;
  margin-bottom: $global-spacing;
  border-bottom: solid 1px rgba($base-color, 0.1);
}

.card__label--ready {
  padding-inline-start: 1rem;
  position: relative;
  &:before {
    content: '';
    height: 10px;
    width: 10px;
    background-color: #6CC268;
    position: absolute;
    border-radius: 999px;
    left: 0;
    top: 0.25rem;
  };
}

.deployments-map {
  margin-bottom: $global-spacing * 4;
  .map-container {
    width: 100%;
  }
  .map-vis .map-vis__legend .key {
    border-inline-start: none;
    padding-inline-start: 0;
    margin-inline-start: 0;
  }

  .map-vis__legend--top-left {
    top: 1rem;

    .form__group {
      margin-bottom: 0;
    }
  }
}

.deployments-key {
  padding: $global-spacing;
  @include media(small-up) {
    padding: 0;
  }
}

.legend__title {
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-bottom: 0;
}

.legend__grandient {
  margin-bottom: 0;

  dt {
    box-shadow: inset 0 0 0 1px rgba($base-color, 0.08);
    font-size: 0;
    line-height: 0;
    height: 0.5rem;
  }

  dd {
    font-size: 0.75rem;
    line-height: 1rem;
    span:nth-child(2) {
      @extend .visually-hidden;
    }
    span:last-child() {
      float: right;
    }
  }
}

.page--deployments {
  .sumstats__item__wrap {
    @include media(medium) {
      width: 240px;
    }
  }
}

.fold-deployments-overview-emergencies {
  .fold__header {
    margin-bottom: 0;
  }

  .fold__header__block {
    margin-left: 0;
    margin-right: 0;
  }
}
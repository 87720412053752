﻿// Button declarations

.button--primary-filled {
  &:hover,
  &:focus {
    background: $red-hover;
    color: $white;
  }

  &.disabled {
    background: $grey-disabled;
  }
}

.button--primary-bounded {
  &:hover,
  &:focus {
    background: $red-hover;
    color: $white;
  }

  &.disabled {
    color: $grey-disabled;
    box-shadow: inset 0 0 0 1px $grey-disabled;
  }
}

.button--home-filled {
  background: $white;
  color: $primary-color;
  &:hover,
  &:focus {
    background: $red-hover;
    color: $white;
  }

  &.disabled {
    background: $grey-disabled;
  }
}

.link--with-icon {
  font-weight: 500;
  &:hover,
  &:focus {
    color: $primary-color;
    opacity: 1;

    span:not(.icon-about-ref) {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }

  &-red {
    color: $primary-color;
    font-weight: 500;
    &:hover,
    &:focus {
      opacity: 1;

      span:not(.icon-about-ref) {
        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

.link--inline {
  font-weight: 500;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $primary-color;
    opacity: 1;
  }
}

.link--popup {
  font-weight: 500;
  color: $primary-color;
  text-transform: uppercase;

  &:hover,
  &:focus {
    opacity: 1;

    span:not(.icon-about-ref) {
      &:hover,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

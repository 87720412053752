@import "../../styles/settings/_variables.scss";
.presenting {
  width: 100%;
  height: 100vh;
  .inner {
    max-width: 1600px;
    margin-top: $global-spacing * 2;
  }

  .fold__header {
    margin-bottom: 0;
  }

  .button--fullscreen {
    border: none;
    @extend .button--text-hidden;
    position: fixed;
    top: $global-spacing/2;
    right: $global-spacing/2;

    &:before {
      content: url('/assets/graphics/layout/x-thin.svg');
    }
  }

  .appeals--fullscreen {
    margin-top: $global-spacing * 2;
  }

  .stats-map .emergencies .emergencies__item {
    &:nth-child(n+8) {
      display: none;
    }

    @include media(xlarge-up) {
      &:nth-child(n+8) {
        display: flex;
      }
    }
  }

  .stats-overall {
    height: 15vh;
    padding: 3vh 0;
    span.sumstats__key {
      font-size: 0.875rem;
      @include media(xlarge-up) {
        font-size: 1rem;
      }
    }
  }
  .stats-map {
    height: 58vh;
    .map-vis {
      height: 58vh;
    }
  }
  .stats-chart {
    height: 21vh;
    padding: 2vh 0;
    .chart__container {
      height: 15vh;
    }
  }
}

.tooltip-button {
  content: url('/assets/graphics/layout/circle-information.svg');
  margin-inline-start: 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.tooltip-button:hover {
  content: url('/assets/graphics/layout/circle-information-hover.svg');
  margin-inline-start: 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.inpage__title--map-fullscreen {
  padding-inline-start: 400px; // width of logo + some padding
  margin: ($global-spacing/2) 0 0 0;
  text-align: center;
  font-size: 1.2rem;
  width: 100%;
}
/* ==========================================================================
   UhOh: Global
   ========================================================================== */

.inpage--uhoh .inpage__body {
  .inner {
    min-height: $global-spacing * 24;
    display: flex;
    align-items: center;
    text-align: center;
  }

  .prose {
    @include column(8/12, $offset: 2/12);

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
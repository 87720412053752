@import "../settings/_variables.scss";

/* ==========================================================================
   Frames
   ========================================================================== */


%base-pos-framed {
	position: relative;
  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    content: "";
    box-shadow: inset 0 0 0 1px $base-alpha-color;
    pointer-events: none;
  }
}

.media-item {
  text-align: center;
  .media-frame {
    &:before {
      z-index: 2;
    }
  }
  img {
    vertical-align: top;
    position: relative;
    z-index: 1;
    max-width: 100%;
  }
  .desktop &,
  .mobile &,
  .overflow & {
    transform: translate(-50%, 0);
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    img {
      width: 100%;
      height: auto;
    }
  }
  .desktop & {
    max-width: 64rem;
    width: 100%;
  }
  .mobile & {
    max-width: 24rem;
    width: 80%;
  }
  .overflow & {
    max-width: 64rem;
    width: 100%;
  }
  @include media(medium-up) {
    .desktop & {
      transform: translate(0, 0);
      width: 200%;
      .set-section.type-left & {
        left: 0;
      }
      .set-section.type-right & {
        left: auto;
        right: 0;
      }
    }
    .mobile & {
      width: 100%;
      .set-section.type-right & {
        transform: translate(50%, 0);
        left: auto;
        right: 50%;
      }
    }
    .overflow & {
      transform: translate(0, 0);
      width: 200%;
      .set-section.type-left & {
        left: 0;
      }
      .set-section.type-right & {
        left: auto;
        right: 0;
      }
    }
  }
}

.desktop .media-frame,
.mobile .media-frame {
  box-shadow: inset 0 0 0 1px $base-alpha-color;
  @extend %base-pos-framed;
  background-color: #fff;
  background-repeat: no-repeat;
}

.desktop .media-frame {
  padding: 2rem 0 0.5rem 0;
  border-radius: $base-border-radius*2 $base-border-radius*2 $base-border-radius $base-border-radius;
  background-image: svg-desktop-top($base-alpha-color);
  background-position: 0.625rem 0.625rem;
  &:before {
    margin: 2rem 0 0.5rem 0;
  }
}

.mobile .media-frame {
  padding: 6rem 1rem;
  border-radius: $base-border-radius*16;
  background-image: svg-mobile-top($base-alpha-color), svg-mobile-bottom($base-alpha-color);
  background-position: center 2.5rem, center calc(100% - 1rem);
  &:before {
    margin: 6rem 1rem;
  }
}

.desktop-group-trio {
  position: relative;
  > .media-frame {
    &:nth-child(1) {
      position: absolute;
      width: 80%;
      top: -60%;
      left: 10%;
      z-index: 10;
    }
    &:nth-child(2) {
      position: absolute;
      width: 90%;
      top: -30%;
      left: 5%;
      z-index: 15;
    }
    &:nth-child(3) {
      width: 100%;
      margin-top: 45%;
      z-index: 20;
    }
  }

  @include media(medium-up) {
    > .media-frame {
      &:nth-child(1) {
        width: 60%;
        left: 40%;
        top: -60%;
      }
      &:nth-child(2) {
        width: 60%;
        left: 20%;
        top: -30%;
      }
      &:nth-child(3) {
        width: 60%;
        margin-top: 25%;
      }
    }
  }
}

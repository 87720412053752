.page--country {
  position: relative;

  .emergencies {
    display: none;
  }

  .inpage__title--inform {
    font-size: 1rem;
    color: rgba($base-color, 0.48);
    margin-inline-start: $global-spacing;
  }

  .inpage__title--inform--score {
    color: $base-color;
  }

  .inpage__meta {
    margin-top: $global-spacing;
    .bold {
      font-weight: $base-font-bold;
    }
    ul {
      list-style: none;
      margin-inline-start: 0;
      font-size: 0.87rem;
      li {
        display: inline-block;
        padding-inline-end: $global-spacing/2;
        &:last-child {
          padding-inline-end: 0;
        };
      }
    }
  }

  .inpage__header {
    .content-list-group {
      @include column(12/12);
    }
    .content-list {
      @include row;
      @include media(medium-up) {
        @include col(50%);
      }
      ul {
        list-style: none;
      }
    }
    .content-list-third {
      @include row;
      @include media(medium-up) {
        @include col(33.33%);
      }
      ul {
        list-style: none;
      }
    }
    .inpage__headline-stats {
      border-inline-end: solid 1px rgba($base-color, 0.1);
    }
    .stats-list {
      li {
        font-size: 2.25rem;
        line-height: 3rem;
        padding-bottom: $global-spacing;
        border-inline-end: 0;
        padding-inline-start: $global-spacing * 3.5;
        border-bottom: solid 1px rgba($base-color, 0.18);
        margin-bottom: $global-spacing;
        &:last-child {
          border-inline-end: none;
          border-bottom: none;
        }
        &:nth-child(2) {
          border-bottom: none;
        };
      }
    }
    dl {
      float: left;
      width: 50%;

    }
  }

  .map-vis {
    height: 25rem;
    @include media(medium-up) {
      height: 30rem;
    }
  }
  .map-vis__legend {
    &--bottom-right {
      @include media(small-up) {
        bottom: 1rem;
      }
    }
    .form {
      width: 35%;
    }
  }

  .key {
    float: left;
    border-inline-start: solid 1px rgba($base-color, 0.2);
    padding-inline-start: $global-spacing/2;
    margin-inline-start: $global-spacing/2;
    width: 55%;
  }

}

.operations__container {
  color: #FFF;
  background-color: $map-color;
  float: left;
  width: 100%;
  margin-bottom: $global-spacing * 2;
  list-style: none;
}

.country__operations {
  float: left;
  width: 100%;
  @include media(medium-up) {
    width: 40%;
  }
  padding: $global-spacing * 2;
  h3 {
    margin-bottom: 0;
  }
  li {
    list-style: none;
  }
  .list-circle {
    display: inline-block;
    color: rgba(#FFF, 0.7);
    padding: 0 $global-spacing / 2;
    margin-inline-end: $global-spacing / 2;
    border-radius: $full-border-radius;
    border: solid 1px rgba(255, 255, 255, 0);
    li {
      width: 10px;
      height: 10px;
      border-radius: 999px;
      background-color: #7e95bb;
      display: inline-block;
      margin-inline-end: $global-spacing/2;
      &:last-child {
        margin-inline-end: 0;
      }
    }
  }
}

.pns-list__item {
  font-size: 0.875rem;
  cursor: pointer;
  border-radius: $full-border-radius;
  > * {
    pointer-events: none;
  }
}

.pns-list__item__canhover {
  &:hover {
    font-weight: $base-font-bold;
    .list-circle { border: solid 1px #FFF; }
  }
}

.pns-list__item__selected {
  background-color: rgba(255, 255, 255, 0.2);
  font-weight: $base-font-bold;
  .list-circle { border: solid 1px #FFF; }
}

.country__map {
  float: right;
  width: 100%;
  @include media(medium-up) {
    width: 60%;
  }

  .inner {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }

  .map-container {
    width: 100%;
    margin-bottom: 0;
  }
}

#per-work-plan {
  .form__group {
    @include form__group;
    margin-bottom: 0;
    
    > .form__inner-header {
      display: block;
      @include media(medium-up) {
        @include column(3/12);
      }
    }

    > .form__inner-body {
      @include media(medium-up) {
        @include column(9/12);
      }
    }
  }
}

.country__pill__wrap {
  justify-content: center;
  flex-wrap: wrap;
}

// ---------------------------
// Three W and components
// ---------------------------
.three-w-container {
  background-color: #fff;
  position: relative;
  overflow-x: auto;
  padding-top: $spacing-2;

  * {
    box-sizing: border-box;
  }

  .fold__header__block {
    margin-left: unset;
    margin-right: unset;
  }

  .block-loading {
    position: absolute;
    border-radius: 0;
    z-index: 11;
    width: 100%;
    margin: 0;
    background-color: rgba(255, 255, 255, .7);
    height: calc(100% - 2 * 2rem);
  }

  >.tc-header {
    display: flex;

    .tc-heading {
      flex-grow: 1;
      margin: 16px;
      font-weight: $heading-font-weight;
    }

    .tc-actions {
      flex-shrink: 0;
      padding: 10px;

      >* {
        margin: 6px;
      }
    }
  }

  .content {
    overflow: hidden;

    .three-w-filters {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      padding: $spacing 0;

      .Select-menu-outer {
        z-index: 11;
        left: 5px;
        width: calc(100% - 10px);
      }

      .tc-select {
        flex-grow: 1;

        .tc-select-label {
          display: none;
        }

        .tc-select-input {
          flex-grow: 1;

          .Select-control {
            border: 0;
            border-radius: 0;
            background-color: $grey-mist;
            border-bottom: 1px solid $primary-color;
            font-size: $font-size-sm;
          }

          .Select-placeholder {
            padding: 0 16px;
            color: $base-color;
            font-weight: $base-font-medium;
          }

          &:focus-within {
            .Select-placeholder {
              color: rgba($base-color, 0.3);
            }
          }

          .Select-input {
            height: auto;

            input {
              padding: 0;
            }
          }

          .Select-multi-value-wrapper {
            padding: 6px;
          }

          .Select-value {
            background-color: $grey-cloud;
            border-radius: 0;
            margin: 1px;
            vertical-align: middle;
            font-size: $font-size-sm;

            .Select-value-icon {
              padding: 2px 3px;
              font-size: $font-size-lg;
            }

            .Select-value-label {
              padding: 2px 3px;
              font-weight: $base-font-medium;
            }
          }
        }
      }
    }

    .export-button {
      flex-shrink: 0;
      margin: 8px;
    }

    .three-w-map-vis {
      position: relative;
      width: 100%;
    }

    .three-w-map-container {
      position: relative;
      width: 100%;

      .three-w-map-wrapper {
        position: relative;
        width: 100%;
        height: 560px;

        .three-w-map {
          width: 100%;
          height: 100%;
        }
      }

      .three-w-stats-region-overview {
        display: flex;
        flex-direction: column;
        width: 280px;
        background-color: rgba(255, 255, 255, .9);
        padding: 16px;
        max-height: 380px;
        position: absolute;
        right: 10px;
        bottom: 56px;
        overflow: auto;

        .tc-header {
          flex-shrink: 0;

          .three-w-region-scale {
            margin-inline-end: 10px;
          }
        }

        .tc-content {
          flex-grow: 1;
          overflow: auto;
          font-size: $font-size-sm;
        }
      }
    }

    .three-w-map-bottom-details {
      display: flex;
      justify-content: space-around;
      bottom: 0;
      left: 0;
      width: 100%;

      >div {
        flex-basis: 0;
        flex-grow: 1;
        background-color: $grey-fog;
        margin: 10px;
      }
    }

    .three-w-project-list-table-container {
      width: 100%;
      padding: 48px 0;
      overflow-x: auto;

      button {
        margin: 1px;
      }
    }
  }
}

.three-w-stats-summary {
  display: flex;

  .summary-element {
    flex-basis: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;

    .summary-element-value {
      font-size: 2rem;
      color: $secondary-color;
      font-weight: $base-font-bold;
    }

    .summary-element-label {
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.three-w-stats-sector-activity {
  .tc-heading {
    text-align: center;
    padding: 16px;
    margin: 0;
    text-transform: uppercase;
  }

  .tc-content {
    width: 100%;
    height: 140px;
  }
}

.three-w-stats-status-overview {
  .tc-heading {
    text-align: center;
    padding: 16px;
    margin: 0;
    text-transform: uppercase;
  }

  .tc-content {
    width: 100%;
    height: 140px;
  }
}

.three-w-project-list-table {
  overflow-x: scroll;
}

.more-actions-dropdown-menu.tc-dropdown-menu {
  font-size: 24px;
  margin: 0;
}

.more-actions-dropdown-container {
  .button {
    width: 100%;
    text-align: left;
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-radius: 0;

    .tc-icon {
      padding: 0 6px;
      display: block;
      font-size: 1.5rem;
      width: 32px;
    }

    .tc-label {
      display: block;
      padding: 0 6px;
    }
  }

  .delete-button {
    color: $primary-color;
  }

  hr {
    margin: 6px 0;
  }
}

.three-w-map-district-detail-popover {
  padding: 10px;
  width: 340px;
  max-height: 240px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, .3);
  border-radius: 5px;

  .detail-popover-title {
    margin: 0;
    padding: 6px;
    flex-shrink: 0;
    color: $primary-color;
  }

  .detail-popover-content {
    flex-grow: 1;
    overflow: auto;

    .popover-project-detail {
      padding: 6px 0px;

      .popover-project-detail-heading {
        font-weight: bold;
        padding: 6px;
      }

      .popover-project-detail-element {
        display: flex;

        .popover-project-detail-element-label {
          padding: 0 6px;
          color: rgba(0, 0, 0, .6);
        }

        .popover-project-detail-element-value {
          padding: 0 6px;
          color: rgba(0, 0, 0, .9);
        }
      }

      .popover-project-detail-last-updated {
        font-size: 10px;
        color: rgba(0, 0, 0, .3);
        margin-bottom: -6px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, .1);
      }
    }
  }
}

.three-w-region-district {
  padding: 10px 0;
}

.tc-project-details-modal {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: auto;
  align-items: center;

  >header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    max-width: $media-large;
    width: 100%;

    >h2 {
      margin: 0;
      padding: 24px;
    }
  }
}

.tc-project-details-modal {
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow: auto;

  >header {
    display: flex;
    align-items: center;

    >h2 {
      margin: 0;
      padding: 24px;
    }
  }

  .tc-project-details {
    max-width: $media-large;
    width: 100%;

    .tc-project-name {
      padding: 0 10px;
      flex-direction: column;

      .tc-label {
        padding-bottom: 0;
      }

      .tc-value {
        padding-top: 0;
        font-size: 32px;
      }
    }

    .tc-section {
      padding: 10px;

      >.tc-text-output {
        padding: 0 10px;
      }

      .tc-section-title {
        padding: 0 14px;
        color: $primary-color;
      }

      .tc-section-content {
        display: flex;

        .tc-text-output {
          padding: 0 10px;
        }
      }
    }

    .tc-date-section {
      display: flex;

      .tc-text-output {
        padding: 0 10px;
      }
    }
  }
}

.tc-project-details {
  z-index: 1;

  .tc-project-name {
    padding: 0 10px;
    flex-direction: column;

    .tc-label {
      padding-bottom: 0;
    }

    .tc-value {
      padding-top: 0;
      font-size: 32px;
    }
  }

  .tc-section {
    padding: 10px;

    >.tc-text-output {
      padding: 0 10px;
    }

    .tc-section-title {
      padding: 0 14px;
      color: $primary-color;
    }

    .tc-section-content {
      display: flex;

      .tc-text-output {
        padding: 0 10px;
      }
    }
  }

  .tc-date-section {
    display: flex;

    .tc-text-output {
      padding: 0 10px;
    }
  }
}

.rich-text-section ul, ol {
  padding: revert;
  list-style-type: revert;
}

.rich-text-section a:hover {
  opacity: 1;
  background-size: 100% 85%;
}

.rich-text-section a {
  background-image: linear-gradient(180deg, transparent 65%, #C02C2C 0);
  color: #000000;
  font-weight: 400;
  padding-bottom: 0.25rem;
  background-size: 100% 140%;
  background-repeat: no-repeat;
  text-decoration: none;
  transition: background-size .4s ease;
}
